<template>
  <container-questions />
</template>

<script>
import ContainerQuestions from "@/containers/common/ContainerQuestions";

export default {
  name: "ViewRoomMobileQuestions",
  components: { ContainerQuestions },
};
</script>
