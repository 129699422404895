<template>
  <transition name="modal">
    <div
      v-show="visible"
      class="smart-modal"
      :class="[
        `smart-modal--${type || 'dialog'}`,
        {
          'smart-modal--no-blocking': noBlocking,
          'smart-modal--desktop': desktop,
        },
      ]"
    >
      <div class="smart-modal__mask" @click.stop="clickModal">
        <ws-icon v-if="maskIcon" class="smart-modal__mask-icon" light color="white">times</ws-icon>
      </div>
      <div ref="panel" class="smart-modal__panel">
        <template v-if="$scopedSlots.full" class="smart-modal__panel-body">
          <slot name="full" />
        </template>
        <template v-else>
          <div class="smart-modal__panel-header">
            <slot name="header" />
          </div>
          <div class="smart-modal__panel-body">
            <slot name="body" />
          </div>
          <div class="smart-modal__panel-footer">
            <slot name="footer">
              <ws-button
                block
                outlined
                color="primary"
                lg
                class="smart-modal__button smart-modal__button--cancel"
                @click.stop="$emit('cancel')"
              >
                {{ textCancel || $t("common.cancel") }}
              </ws-button>
              <ws-button
                block
                color="primary"
                lg
                class="smart-modal__button smart-modal__button--confirm"
                @click.stop="$emit('confirm')"
              >
                {{ textConfirm || $t("common.accept") }}
              </ws-button>
            </slot>
          </div>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

export default {
  name: "SmartModal",
  components: {
    WsIcon,
    WsButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    /**
     * The modal form has 2 types: dialogue and form.
     * Each has its own default style values (position, padding, etc.).
     */
    type: {
      type: String,
      default: "dialog",
      validator: value => {
        return !value || ["dialog", "form"].includes(value);
      },
    },
    /**
     * No overlay, display form as popup.
     * !Note: only valid for desktop mode.
     */
    noBlocking: {
      type: Boolean,
      default: false,
    },
    /**
     * Dialog cancel button text
     */
    textCancel: {
      type: String,
      default: "",
    },
    /**
     * Dialog confirm button text
     */
    textConfirm: {
      type: String,
      default: "",
    },
    maskIcon: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    desktop() {
      return this.$mqFromTablet;
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.moveComponentIntoRoot();
      }
    },
  },
  mounted() {
    this.moveComponentIntoRoot();
  },
  beforeDestroy() {
    this.$el.remove();
  },
  methods: {
    clickModal() {
      this.$emit("close", false);
    },
    moveComponentIntoRoot() {
      document.querySelector(".app")?.appendChild(this.$el);
    },
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/_vars";

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .simple-modal__container,
.modal-leave-active .simple-modal__container {
  transform: scale(1.1);
}

.smart-modal {
  --page-nav-height: 60px;
  --modal-default-indent: 30px;

  /* 1. Modal container|wrapper styles */
  position: fixed;
  top: var(--page-nav-height);
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @modal-zindex;
  display: flex;
  justify-content: center;
  max-width: 100vw;
  transition: all 0.3s ease;

  &__mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: @modal-overlay-color;
  }

  &__mask-icon {
    position: fixed;
    top: 20px;
    right: 20px;
  }

  /**
   * In the mobile form, be sure to leave the site header available (main menu).
   * In order not to overlap it.
   */
  &--desktop {
    top: 0;
  }

  &--dialog {
    align-items: center;
  }

  /**
   * Alignment of the form on the bottom as a sliding panel in mobile mode.
   * In the desktop, the form will be centered on the screen.
   */
  &--form {
    align-items: flex-end;
  }

  &--form&--desktop {
    align-items: center;
  }

  /**
   * In desktop mode display modal as popup, no overlay.
   * Always below main menu.
   */
  &--desktop&--no-blocking {
    top: calc(var(--modal-default-indent) + var(--page-nav-height));
    right: unset;
    bottom: unset;
    left: var(--modal-default-indent);
    background-color: transparent;
  }

  /* 2. Modal form|panel styles */
  &__panel {
    z-index: 1;
    display: grid;
    grid-template-areas:
      "header"
      "body"
      "footer";
    grid-template-rows: max-content 1fr max-content;
    grid-template-columns: 1fr;
    width: 300px;
    max-width: 100%;
    max-height: 90%;
    overflow: hidden;
    background-color: @modal-form-background;
    border-radius: @modal-form-border-radius;
    box-shadow: @modal-form-box-shadow;
    transition: all 0.3s ease;

    &-header {
      grid-area: header;
    }

    &-body {
      position: relative;
      grid-area: body;
      overflow: auto;
    }

    &-footer {
      grid-area: footer;
    }
  }

  &--dialog &__panel {
    width: 295px;
    max-width: 90%;
    padding: 30px 20px 20px;
  }

  &--form &__panel {
    width: 100%;
    padding: 30px 15px 15px;
  }

  &--form&--desktop &__panel {
    width: auto;
    min-width: 375px;
    max-height: 85vh;
  }

  /**
   * Alignment of the form on the bottom as a sliding panel in mobile mode.
   * Display the panel as sticked to the bottom.
   */
  &--form:not(&--desktop) &__panel {
    border-radius: @modal-form-border-radius @modal-form-border-radius 0 0;
  }

  &--desktop &__panel {
    max-width: calc(100vw - 2 * var(--modal-default-indent));
    max-height: calc(100vh - 2 * var(--page-nav-height));
  }

  &--no-blocking &__panel {
    max-height: calc(100vh - var(--page-nav-height) - 2 * var(--modal-default-indent));
  }

  &__panel-footer {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
    margin-top: 20px;
  }

  &--dialog &__panel-header {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: @black;
    text-align: center;

    ::v-deep em {
      font-style: inherit;
      color: @modal-form-title-accent-color;
    }
  }
}
</style>
